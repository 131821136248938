<template>
    <div>
        <Consolidation />
        <LongPressHelp />
    </div>
</template>

<script>
import Consolidation from '@apps/Consolidation'
import pageMeta from '@/mixins/pageMeta'
import LongPressHelp from '@/components/LongPressHelp.vue'
export default {
    mixins: [pageMeta],
    components: {
        Consolidation,
        LongPressHelp,
    }
}
</script>